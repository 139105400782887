import React from "react";
import styled from "styled-components";
import Footer from "./footer";
import Header from "./header";
import Topbar from "./topbar";

const Layout = ({ children, headerWithBg }) => {
  return (
    <Layouts>
      <Topbar />
      <Header headerWithBg={headerWithBg} />
      <main>{children}</main>
      <Footer />
    </Layouts>
  );
};

const Layouts = styled.div``;

export default Layout;
