import React from "react";
import styled from "styled-components";
import Container from "./container";
import LanguageSelector from "./language-selector";
import { Trans } from "gatsby-plugin-react-i18next";

const Footer = () => {
  return (
    <Footers>
      <Container className="wrapper">
        <Copyright>
          © <a href="https://www.indibleu.com">Indibleu </a>
          <Trans>2022 All Rights Reserved</Trans>
        </Copyright>
        <Lang>
          <LanguageSelector />
        </Lang>
      </Container>
    </Footers>
  );
};

const Footers = styled("footer")`
  font-size: 0.8em;
  font-weight: 300;
  background-color: ${({ theme }) => theme.colors.blacks[3]};
  color: ${({ theme }) => theme.colors.whites[1]};
  width: 100%;
  padding: 2em 0;

  .wrapper {
    display: flex;
    justify-content: space-around;
  }
`;

const Copyright = styled.div``;

const Lang = styled.div``;

export default Footer;
