import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import LanguageSelector from "./language-selector";
import Container from "./container";

const Topbar = () => {
  return (
    <Topbars>
      <Container className="wrapper">
        <Contacts>
          <StaticQuery
            query={graphql`
              query ContactsQuery {
                markdownRemark(
                  frontmatter: { templateKey: { eq: "contacts" } }
                ) {
                  frontmatter {
                    contacts {
                      label
                      link
                      icon
                    }
                  }
                }
              }
            `}
            render={(data) => {
              const contacts = data.markdownRemark.frontmatter.contacts;
              console.log(contacts);
              return (
                <ul>
                  {contacts.map(({ icon, label, link }) => {
                    return (
                      <li>
                        <a href={link}>
                          <svg>
                            <use href={`#${icon}`} />
                          </svg>
                          <span>{label}</span>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              );
            }}
          />
        </Contacts>
        <Langs>
          <LanguageSelector />
        </Langs>
      </Container>
    </Topbars>
  );
};

export default Topbar;

const Topbars = styled.div`
  font-size: 0.8em;
  font-weight: 300;
  background-color: ${({ theme }) => theme.colors.blacks[3]};
  color: ${({ theme }) => theme.colors.whites[1]};
  width: 100%;
  padding: 1em 0;

  @media ${({ theme }) => theme.device.tabport} {
    display: none;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
  }
`;

const Contacts = styled.div`
  ul {
    display: flex;
    li a {
      display: flex;
      align-items: center;
      transition: color 0.3s ease-out;
      &:hover {
        color: ${({ theme }) => theme.colors.secondary};
        svg {
          fill: ${({ theme }) => theme.colors.whites[1]};
        }
      }
    }
    > * + * {
      margin-left: 2em;
    }
    svg {
      width: 1.2em;
      height: 1.2em;
      margin-right: 0.6em;
      fill: ${({ theme }) => theme.colors.secondary};
      transition: fill 0.3s ease-out;
    }
  }
`;

const Langs = styled.div``;
